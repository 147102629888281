<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form">
        <form @submit.prevent="submit">
          <!-- form -->
          <div class="row">
            <div class="col-6">
              <base-input label="Título da categoria" v-model="form.title"></base-input>
            </div>

            <div class="col-4">
              <label>Ícone</label>

              <el-select v-model="form.icon" placeholder="Selecione o ícone" class="selectIcon">
                <el-option v-for="item in icons" :key="item.value" :label="item.label" :value="item.value">
                  <span style="float: left">
                    <i :class="'fa fa-'+item.value"></i>
                  </span>
                  <span style="float: right;">{{item.label | capitalize}}</span>
                </el-option>
              </el-select>
            </div>

            <div class="col-1 d-flex align-items-center">
              <div class="form-control d-flex align-items-center justify-content-center h-40 mt-1">
                <template v-if="form.icon !== ''">
                  <div class="position-absolute iconMask text-white d-flex justify-content-center align-items-center" @click="form.icon = ''">
                    <i class="fa fa-2x fa-times"></i>
                  </div>
                  <i :class="'fa fa-2x fa-'+form.icon"></i>
                </template>
              </div>
            </div>

            <div class="col-1">
              <label>Cor</label>

              <div class="form-control colorPicker">
                <input type="color" v-model="form.color" required />
              </div>
            </div>

            <div class="col-12">
              <h4>
                Tipo da categoria - selecione um existente ou crie um novo
              </h4>
            </div>

            <div class="col-6">
              <label>Tipo da categoria (existente)</label>
              <select v-model="form.type" class="form-control">
                <option v-for="(type) in types" :key="type.type" :value="type.type">{{ type.type }}</option>
              </select>
            </div>

            <div class="col-6">
              <base-input label="Tipo da categoria (novo)" v-model="form.type"></base-input>
            </div>

            <div class="col-6">
              <label class="mr-3 mb-0">Status</label>
              <el-radio-group v-model="form.status">
                <el-radio class="mb-0" :label="1">Ativo</el-radio>
                <el-radio class="mb-0" :label="0">Inativo</el-radio>
              </el-radio-group>
            </div>

            <!-- botoes -->
            <div class="col-12 mt-3">
              <base-button @click.prevent.stop="goBack()" type="danger">Cancelar</base-button>
              <base-button native-type="submit" type="success" class="ml-3">Salvar</base-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import { ColorPicker, Tag } from 'element-ui'
import Vue from 'vue'
import swal from 'sweetalert2'
import vSelect from 'vue-select'
import Icons from '@/util/Icons'
Vue.component('v-select', vSelect)
export default {
  data: () => ({
    icons: [],
    form: {
      status: 0,
      icon: '',
      color: '#000000'
    },
    types: []
  }),
  methods: {
    async getData () {
      const id = this.$route.params.id || false
      if (id) {
        this.form = (await this.$http.get(`categories/${id}`)).data.data
      }
    },
    async submit () {
      if (this.validate()) {
        const id = this.$route.params.id || false
        let status
        try {
          if (id) {
            status = (await this.$http.put(`categories/${id}`, this.form)).status
          }
          if (!id) {
            status = (await this.$http.post('categories', this.form)).status
          }

          if (status === 200) {
            await swal.fire({
              title: 'Salvo!',
              text: this.form.title + ' salvo com sucesso.',
              showConfirmButton: false,
              timer: 2000
            })
            await this.$router.push('/categorias')
          } else {
            await swal.fire({
              title: 'Erro!',
              text: 'Ocorreu um erro, tente novamente mais tarde.',
              denyButtonText: 'Ok',
              showDenyButton: true,
              showConfirmButton: false
            })
          }
        } catch (e) {
          status = e.response.status
        }
      } else {
        await swal.fire({
          title: 'Erro!',
          text: 'Por favor, preencha todos os campos obrigatórios.',
          denyButtonText: 'Ok',
          showDenyButton: true,
          showConfirmButton: false
        })
      }
    },
    goBack () {
      window.history.go(-1)
    },
    validate () {
      const form = this.form
      if (!form.title || !form.title.trim() || !form.icon || !form.type) {
        swal.fire({
          title: 'Erro!',
          text: 'Por favor, preencha todos os campos obrigatórios.',
          denyButtonText: 'Ok',
          showDenyButton: true,
          showConfirmButton: false
        })
        return false
      }
      return true
    },
    async getTypes () {
      const url = 'types-of-categories'
      const { data } = await this.$http.get(url)
      this.types = data.data
    },
    handleClose () {
      this.form.color.splice(this.form.color, 1)
    },
    handleColorPicker (e) {
      console.log(e)
    }
  },
  mounted () {
    this.getData()
    this.getTypes()
    this.icons = Icons
  },
  components: {
    [ColorPicker.name]: ColorPicker,
    [Tag.name]: Tag
  },
  filters: {
    capitalize (value) {
      if (!value) return value
      value = value.toString().replace('-', ' ')
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
}
</script>
<style>
.menuCategory.dropdown-menu{
  height: calc(100vh - 200px);
  overflow-y: scroll;
}
.menuCategory .dropdown-item{
  display: flex !important;
  align-items: center;
}
.menuCategory .dropdown-item .icon{
  width: 50px;
}
.menuCategory.dropdown-menu i{
  margin-right: 0 !important;
}
.el-color-picker{
  width: 100% !important;
}
.el-color-picker .el-color-picker__trigger{
  width: 100% !important;
}
.h-40{
  height: 40px !important;
}
.iconMask{
  background-color: rgba(0, 0, 0, 0.7);
  width: calc(100% - 32px);
  height: 40px !important;
  border-radius: 0.4285rem;
  opacity: 0;
  transition: all linear 0.2s;
  cursor: pointer;
}
.iconMask:hover{
  opacity: 1;
}
.form-control.colorPicker{
  padding: 0 !important;
  overflow: hidden;
}
.colorPicker input{
  width: calc(100% + 15px);
  height: calc(100% + 15px);
  margin-left: -7.5px;
  margin-top: -7.5px;
  padding: 0 !important;
  border: none !important;
  cursor: pointer;
}
.el-select.selectIcon input{
  color: #222a42 !important;
  border-color: rgba(29, 37, 59, 0.5) !important;
  border-radius: 0.4285rem;
}
.el-select.selectIcon .el-input__suffix i {
  color: #222a42 !important;
}
.el-select.selectIcon .el-input__inner::placeholder,
.el-select.selectIcon .el-input__inner {
  color: #222a42 !important;
}
</style>
