const icons = [
  {
    label: 'ad',
    value: 'ad'
  },
  {
    label: 'address-book',
    value: 'address-book'
  },
  {
    label: 'address-card',
    value: 'address-card'
  },
  {
    label: 'adjust',
    value: 'adjust'
  },
  {
    label: 'air-freshener',
    value: 'air-freshener'
  },
  {
    label: 'align-center',
    value: 'align-center'
  },
  {
    label: 'align-justify',
    value: 'align-justify'
  },
  {
    label: 'align-left',
    value: 'align-left'
  },
  {
    label: 'align-right',
    value: 'align-right'
  },
  {
    label: 'allergies',
    value: 'allergies'
  },
  {
    label: 'ambulance',
    value: 'ambulance'
  },
  {
    label: 'american-sign-language-interpreting',
    value: 'american-sign-language-interpreting'
  },
  {
    label: 'anchor',
    value: 'anchor'
  },
  {
    label: 'angle-double-down',
    value: 'angle-double-down'
  },
  {
    label: 'angle-double-left',
    value: 'angle-double-left'
  },
  {
    label: 'angle-double-right',
    value: 'angle-double-right'
  },
  {
    label: 'angle-double-up',
    value: 'angle-double-up'
  },
  {
    label: 'angle-down',
    value: 'angle-down'
  },
  {
    label: 'angle-left',
    value: 'angle-left'
  },
  {
    label: 'angle-right',
    value: 'angle-right'
  },
  {
    label: 'angle-up',
    value: 'angle-up'
  },
  {
    label: 'angry',
    value: 'angry'
  },
  {
    label: 'ankh',
    value: 'ankh'
  },
  {
    label: 'apple-alt',
    value: 'apple-alt'
  },
  {
    label: 'archive',
    value: 'archive'
  },
  {
    label: 'archway',
    value: 'archway'
  },
  {
    label: 'arrow-alt-circle-down',
    value: 'arrow-alt-circle-down'
  },
  {
    label: 'arrow-alt-circle-left',
    value: 'arrow-alt-circle-left'
  },
  {
    label: 'arrow-alt-circle-right',
    value: 'arrow-alt-circle-right'
  },
  {
    label: 'arrow-alt-circle-up',
    value: 'arrow-alt-circle-up'
  },
  {
    label: 'arrow-circle-down',
    value: 'arrow-circle-down'
  },
  {
    label: 'arrow-circle-left',
    value: 'arrow-circle-left'
  },
  {
    label: 'arrow-circle-right',
    value: 'arrow-circle-right'
  },
  {
    label: 'arrow-circle-up',
    value: 'arrow-circle-up'
  },
  {
    label: 'arrow-down',
    value: 'arrow-down'
  },
  {
    label: 'arrow-left',
    value: 'arrow-left'
  },
  {
    label: 'arrow-right',
    value: 'arrow-right'
  },
  {
    label: 'arrow-up',
    value: 'arrow-up'
  },
  {
    label: 'arrows-alt',
    value: 'arrows-alt'
  },
  {
    label: 'arrows-alt-h',
    value: 'arrows-alt-h'
  },
  {
    label: 'arrows-alt-v',
    value: 'arrows-alt-v'
  },
  {
    label: 'assistive-listening-systems',
    value: 'assistive-listening-systems'
  },
  {
    label: 'asterisk',
    value: 'asterisk'
  },
  {
    label: 'at',
    value: 'at'
  },
  {
    label: 'atlas',
    value: 'atlas'
  },
  {
    label: 'atom',
    value: 'atom'
  },
  {
    label: 'audio-description',
    value: 'audio-description'
  },
  {
    label: 'award',
    value: 'award'
  },
  {
    label: 'baby',
    value: 'baby'
  },
  {
    label: 'baby-carriage',
    value: 'baby-carriage'
  },
  {
    label: 'backspace',
    value: 'backspace'
  },
  {
    label: 'backward',
    value: 'backward'
  },
  {
    label: 'bacon',
    value: 'bacon'
  },
  {
    label: 'bahai',
    value: 'bahai'
  },
  {
    label: 'balance-scale',
    value: 'balance-scale'
  },
  {
    label: 'balance-scale-left',
    value: 'balance-scale-left'
  },
  {
    label: 'balance-scale-right',
    value: 'balance-scale-right'
  },
  {
    label: 'ban',
    value: 'ban'
  },
  {
    label: 'band-aid',
    value: 'band-aid'
  },
  {
    label: 'barcode',
    value: 'barcode'
  },
  {
    label: 'bars',
    value: 'bars'
  },
  {
    label: 'baseball-ball',
    value: 'baseball-ball'
  },
  {
    label: 'basketball-ball',
    value: 'basketball-ball'
  },
  {
    label: 'bath',
    value: 'bath'
  },
  {
    label: 'battery-empty',
    value: 'battery-empty'
  },
  {
    label: 'battery-full',
    value: 'battery-full'
  },
  {
    label: 'battery-half',
    value: 'battery-half'
  },
  {
    label: 'battery-quarter',
    value: 'battery-quarter'
  },
  {
    label: 'battery-three-quarters',
    value: 'battery-three-quarters'
  },
  {
    label: 'bed',
    value: 'bed'
  },
  {
    label: 'beer',
    value: 'beer'
  },
  {
    label: 'bell',
    value: 'bell'
  },
  {
    label: 'bell-slash',
    value: 'bell-slash'
  },
  {
    label: 'bezier-curve',
    value: 'bezier-curve'
  },
  {
    label: 'bible',
    value: 'bible'
  },
  {
    label: 'bicycle',
    value: 'bicycle'
  },
  {
    label: 'biking',
    value: 'biking'
  },
  {
    label: 'binoculars',
    value: 'binoculars'
  },
  {
    label: 'biohazard',
    value: 'biohazard'
  },
  {
    label: 'birthday-cake',
    value: 'birthday-cake'
  },
  {
    label: 'blender',
    value: 'blender'
  },
  {
    label: 'blender-phone',
    value: 'blender-phone'
  },
  {
    label: 'blind',
    value: 'blind'
  },
  {
    label: 'blog',
    value: 'blog'
  },
  {
    label: 'bold',
    value: 'bold'
  },
  {
    label: 'bolt',
    value: 'bolt'
  },
  {
    label: 'bomb',
    value: 'bomb'
  },
  {
    label: 'bone',
    value: 'bone'
  },
  {
    label: 'bong',
    value: 'bong'
  },
  {
    label: 'book',
    value: 'book'
  },
  {
    label: 'book-dead',
    value: 'book-dead'
  },
  {
    label: 'book-medical',
    value: 'book-medical'
  },
  {
    label: 'book-open',
    value: 'book-open'
  },
  {
    label: 'book-reader',
    value: 'book-reader'
  },
  {
    label: 'bookmark',
    value: 'bookmark'
  },
  {
    label: 'border-all',
    value: 'border-all'
  },
  {
    label: 'border-none',
    value: 'border-none'
  },
  {
    label: 'border-style',
    value: 'border-style'
  },
  {
    label: 'bowling-ball',
    value: 'bowling-ball'
  },
  {
    label: 'box',
    value: 'box'
  },
  {
    label: 'box-open',
    value: 'box-open'
  },
  {
    label: 'boxes',
    value: 'boxes'
  },
  {
    label: 'braille',
    value: 'braille'
  },
  {
    label: 'brain',
    value: 'brain'
  },
  {
    label: 'bread-slice',
    value: 'bread-slice'
  },
  {
    label: 'briefcase',
    value: 'briefcase'
  },
  {
    label: 'briefcase-medical',
    value: 'briefcase-medical'
  },
  {
    label: 'broadcast-tower',
    value: 'broadcast-tower'
  },
  {
    label: 'broom',
    value: 'broom'
  },
  {
    label: 'brush',
    value: 'brush'
  },
  {
    label: 'bug',
    value: 'bug'
  },
  {
    label: 'building',
    value: 'building'
  },
  {
    label: 'bullhorn',
    value: 'bullhorn'
  },
  {
    label: 'bullseye',
    value: 'bullseye'
  },
  {
    label: 'burn',
    value: 'burn'
  },
  {
    label: 'bus',
    value: 'bus'
  },
  {
    label: 'bus-alt',
    value: 'bus-alt'
  },
  {
    label: 'business-time',
    value: 'business-time'
  },
  {
    label: 'calculator',
    value: 'calculator'
  },
  {
    label: 'calendar',
    value: 'calendar'
  },
  {
    label: 'calendar-alt',
    value: 'calendar-alt'
  },
  {
    label: 'calendar-check',
    value: 'calendar-check'
  },
  {
    label: 'calendar-day',
    value: 'calendar-day'
  },
  {
    label: 'calendar-minus',
    value: 'calendar-minus'
  },
  {
    label: 'calendar-plus',
    value: 'calendar-plus'
  },
  {
    label: 'calendar-times',
    value: 'calendar-times'
  },
  {
    label: 'calendar-week',
    value: 'calendar-week'
  },
  {
    label: 'camera',
    value: 'camera'
  },
  {
    label: 'camera-retro',
    value: 'camera-retro'
  },
  {
    label: 'campground',
    value: 'campground'
  },
  {
    label: 'candy-cane',
    value: 'candy-cane'
  },
  {
    label: 'cannabis',
    value: 'cannabis'
  },
  {
    label: 'capsules',
    value: 'capsules'
  },
  {
    label: 'car',
    value: 'car'
  },
  {
    label: 'car-alt',
    value: 'car-alt'
  },
  {
    label: 'car-battery',
    value: 'car-battery'
  },
  {
    label: 'car-crash',
    value: 'car-crash'
  },
  {
    label: 'car-side',
    value: 'car-side'
  },
  {
    label: 'caravan',
    value: 'caravan'
  },
  {
    label: 'caret-down',
    value: 'caret-down'
  },
  {
    label: 'caret-left',
    value: 'caret-left'
  },
  {
    label: 'caret-right',
    value: 'caret-right'
  },
  {
    label: 'caret-square-down',
    value: 'caret-square-down'
  },
  {
    label: 'caret-square-left',
    value: 'caret-square-left'
  },
  {
    label: 'caret-square-right',
    value: 'caret-square-right'
  },
  {
    label: 'caret-square-up',
    value: 'caret-square-up'
  },
  {
    label: 'caret-up',
    value: 'caret-up'
  },
  {
    label: 'carrot',
    value: 'carrot'
  },
  {
    label: 'cart-arrow-down',
    value: 'cart-arrow-down'
  },
  {
    label: 'cart-plus',
    value: 'cart-plus'
  },
  {
    label: 'cash-register',
    value: 'cash-register'
  },
  {
    label: 'cat',
    value: 'cat'
  },
  {
    label: 'certificate',
    value: 'certificate'
  },
  {
    label: 'chair',
    value: 'chair'
  },
  {
    label: 'chalkboard',
    value: 'chalkboard'
  },
  {
    label: 'chalkboard-teacher',
    value: 'chalkboard-teacher'
  },
  {
    label: 'charging-station',
    value: 'charging-station'
  },
  {
    label: 'chart-area',
    value: 'chart-area'
  },
  {
    label: 'chart-bar',
    value: 'chart-bar'
  },
  {
    label: 'chart-line',
    value: 'chart-line'
  },
  {
    label: 'chart-pie',
    value: 'chart-pie'
  },
  {
    label: 'check',
    value: 'check'
  },
  {
    label: 'check-circle',
    value: 'check-circle'
  },
  {
    label: 'check-double',
    value: 'check-double'
  },
  {
    label: 'check-square',
    value: 'check-square'
  },
  {
    label: 'cheese',
    value: 'cheese'
  },
  {
    label: 'chess',
    value: 'chess'
  },
  {
    label: 'chess-bishop',
    value: 'chess-bishop'
  },
  {
    label: 'chess-board',
    value: 'chess-board'
  },
  {
    label: 'chess-king',
    value: 'chess-king'
  },
  {
    label: 'chess-knight',
    value: 'chess-knight'
  },
  {
    label: 'chess-pawn',
    value: 'chess-pawn'
  },
  {
    label: 'chess-queen',
    value: 'chess-queen'
  },
  {
    label: 'chess-rook',
    value: 'chess-rook'
  },
  {
    label: 'chevron-circle-down',
    value: 'chevron-circle-down'
  },
  {
    label: 'chevron-circle-left',
    value: 'chevron-circle-left'
  },
  {
    label: 'chevron-circle-right',
    value: 'chevron-circle-right'
  },
  {
    label: 'chevron-circle-up',
    value: 'chevron-circle-up'
  },
  {
    label: 'chevron-down',
    value: 'chevron-down'
  },
  {
    label: 'chevron-left',
    value: 'chevron-left'
  },
  {
    label: 'chevron-right',
    value: 'chevron-right'
  },
  {
    label: 'chevron-up',
    value: 'chevron-up'
  },
  {
    label: 'child',
    value: 'child'
  },
  {
    label: 'church',
    value: 'church'
  },
  {
    label: 'circle',
    value: 'circle'
  },
  {
    label: 'circle-notch',
    value: 'circle-notch'
  },
  {
    label: 'city',
    value: 'city'
  },
  {
    label: 'clinic-medical',
    value: 'clinic-medical'
  },
  {
    label: 'clipboard',
    value: 'clipboard'
  },
  {
    label: 'clipboard-check',
    value: 'clipboard-check'
  },
  {
    label: 'clipboard-list',
    value: 'clipboard-list'
  },
  {
    label: 'clock',
    value: 'clock'
  },
  {
    label: 'clone',
    value: 'clone'
  },
  {
    label: 'closed-captioning',
    value: 'closed-captioning'
  },
  {
    label: 'cloud',
    value: 'cloud'
  },
  {
    label: 'cloud-download-alt',
    value: 'cloud-download-alt'
  },
  {
    label: 'cloud-meatball',
    value: 'cloud-meatball'
  },
  {
    label: 'cloud-moon',
    value: 'cloud-moon'
  },
  {
    label: 'cloud-moon-rain',
    value: 'cloud-moon-rain'
  },
  {
    label: 'cloud-rain',
    value: 'cloud-rain'
  },
  {
    label: 'cloud-showers-heavy',
    value: 'cloud-showers-heavy'
  },
  {
    label: 'cloud-sun',
    value: 'cloud-sun'
  },
  {
    label: 'cloud-sun-rain',
    value: 'cloud-sun-rain'
  },
  {
    label: 'cloud-upload-alt',
    value: 'cloud-upload-alt'
  },
  {
    label: 'cocktail',
    value: 'cocktail'
  },
  {
    label: 'code',
    value: 'code'
  },
  {
    label: 'code-branch',
    value: 'code-branch'
  },
  {
    label: 'coffee',
    value: 'coffee'
  },
  {
    label: 'cog',
    value: 'cog'
  },
  {
    label: 'cogs',
    value: 'cogs'
  },
  {
    label: 'coins',
    value: 'coins'
  },
  {
    label: 'columns',
    value: 'columns'
  },
  {
    label: 'comment',
    value: 'comment'
  },
  {
    label: 'comment-alt',
    value: 'comment-alt'
  },
  {
    label: 'comment-dollar',
    value: 'comment-dollar'
  },
  {
    label: 'comment-dots',
    value: 'comment-dots'
  },
  {
    label: 'comment-medical',
    value: 'comment-medical'
  },
  {
    label: 'comment-slash',
    value: 'comment-slash'
  },
  {
    label: 'comments',
    value: 'comments'
  },
  {
    label: 'comments-dollar',
    value: 'comments-dollar'
  },
  {
    label: 'compact-disc',
    value: 'compact-disc'
  },
  {
    label: 'compass',
    value: 'compass'
  },
  {
    label: 'compress',
    value: 'compress'
  },
  {
    label: 'compress-alt',
    value: 'compress-alt'
  },
  {
    label: 'compress-arrows-alt',
    value: 'compress-arrows-alt'
  },
  {
    label: 'concierge-bell',
    value: 'concierge-bell'
  },
  {
    label: 'cookie',
    value: 'cookie'
  },
  {
    label: 'cookie-bite',
    value: 'cookie-bite'
  },
  {
    label: 'copy',
    value: 'copy'
  },
  {
    label: 'copyright',
    value: 'copyright'
  },
  {
    label: 'couch',
    value: 'couch'
  },
  {
    label: 'credit-card',
    value: 'credit-card'
  },
  {
    label: 'crop',
    value: 'crop'
  },
  {
    label: 'crop-alt',
    value: 'crop-alt'
  },
  {
    label: 'cross',
    value: 'cross'
  },
  {
    label: 'crosshairs',
    value: 'crosshairs'
  },
  {
    label: 'crow',
    value: 'crow'
  },
  {
    label: 'crown',
    value: 'crown'
  },
  {
    label: 'crutch',
    value: 'crutch'
  },
  {
    label: 'cube',
    value: 'cube'
  },
  {
    label: 'cubes',
    value: 'cubes'
  },
  {
    label: 'cut',
    value: 'cut'
  },
  {
    label: 'database',
    value: 'database'
  },
  {
    label: 'deaf',
    value: 'deaf'
  },
  {
    label: 'democrat',
    value: 'democrat'
  },
  {
    label: 'desktop',
    value: 'desktop'
  },
  {
    label: 'dharmachakra',
    value: 'dharmachakra'
  },
  {
    label: 'diagnoses',
    value: 'diagnoses'
  },
  {
    label: 'dice',
    value: 'dice'
  },
  {
    label: 'dice-d20',
    value: 'dice-d20'
  },
  {
    label: 'dice-d6',
    value: 'dice-d6'
  },
  {
    label: 'dice-five',
    value: 'dice-five'
  },
  {
    label: 'dice-four',
    value: 'dice-four'
  },
  {
    label: 'dice-one',
    value: 'dice-one'
  },
  {
    label: 'dice-six',
    value: 'dice-six'
  },
  {
    label: 'dice-three',
    value: 'dice-three'
  },
  {
    label: 'dice-two',
    value: 'dice-two'
  },
  {
    label: 'digital-tachograph',
    value: 'digital-tachograph'
  },
  {
    label: 'directions',
    value: 'directions'
  },
  {
    label: 'divide',
    value: 'divide'
  },
  {
    label: 'dizzy',
    value: 'dizzy'
  },
  {
    label: 'dna',
    value: 'dna'
  },
  {
    label: 'dog',
    value: 'dog'
  },
  {
    label: 'dollar-sign',
    value: 'dollar-sign'
  },
  {
    label: 'dolly',
    value: 'dolly'
  },
  {
    label: 'dolly-flatbed',
    value: 'dolly-flatbed'
  },
  {
    label: 'donate',
    value: 'donate'
  },
  {
    label: 'door-closed',
    value: 'door-closed'
  },
  {
    label: 'door-open',
    value: 'door-open'
  },
  {
    label: 'dot-circle',
    value: 'dot-circle'
  },
  {
    label: 'dove',
    value: 'dove'
  },
  {
    label: 'download',
    value: 'download'
  },
  {
    label: 'drafting-compass',
    value: 'drafting-compass'
  },
  {
    label: 'dragon',
    value: 'dragon'
  },
  {
    label: 'draw-polygon',
    value: 'draw-polygon'
  },
  {
    label: 'drum',
    value: 'drum'
  },
  {
    label: 'drum-steelpan',
    value: 'drum-steelpan'
  },
  {
    label: 'drumstick-bite',
    value: 'drumstick-bite'
  },
  {
    label: 'dumbbell',
    value: 'dumbbell'
  },
  {
    label: 'dumpster',
    value: 'dumpster'
  },
  {
    label: 'dumpster-fire',
    value: 'dumpster-fire'
  },
  {
    label: 'dungeon',
    value: 'dungeon'
  },
  {
    label: 'edit',
    value: 'edit'
  },
  {
    label: 'egg',
    value: 'egg'
  },
  {
    label: 'eject',
    value: 'eject'
  },
  {
    label: 'ellipsis-h',
    value: 'ellipsis-h'
  },
  {
    label: 'ellipsis-v',
    value: 'ellipsis-v'
  },
  {
    label: 'envelope',
    value: 'envelope'
  },
  {
    label: 'envelope-open',
    value: 'envelope-open'
  },
  {
    label: 'envelope-open-text',
    value: 'envelope-open-text'
  },
  {
    label: 'envelope-square',
    value: 'envelope-square'
  },
  {
    label: 'equals',
    value: 'equals'
  },
  {
    label: 'eraser',
    value: 'eraser'
  },
  {
    label: 'ethernet',
    value: 'ethernet'
  },
  {
    label: 'euro-sign',
    value: 'euro-sign'
  },
  {
    label: 'exchange-alt',
    value: 'exchange-alt'
  },
  {
    label: 'exclamation',
    value: 'exclamation'
  },
  {
    label: 'exclamation-circle',
    value: 'exclamation-circle'
  },
  {
    label: 'exclamation-triangle',
    value: 'exclamation-triangle'
  },
  {
    label: 'expand',
    value: 'expand'
  },
  {
    label: 'expand-alt',
    value: 'expand-alt'
  },
  {
    label: 'expand-arrows-alt',
    value: 'expand-arrows-alt'
  },
  {
    label: 'external-link-alt',
    value: 'external-link-alt'
  },
  {
    label: 'external-link-square-alt',
    value: 'external-link-square-alt'
  },
  {
    label: 'eye',
    value: 'eye'
  },
  {
    label: 'eye-dropper',
    value: 'eye-dropper'
  },
  {
    label: 'eye-slash',
    value: 'eye-slash'
  },
  {
    label: 'fan',
    value: 'fan'
  },
  {
    label: 'fast-backward',
    value: 'fast-backward'
  },
  {
    label: 'fast-forward',
    value: 'fast-forward'
  },
  {
    label: 'fax',
    value: 'fax'
  },
  {
    label: 'feather',
    value: 'feather'
  },
  {
    label: 'feather-alt',
    value: 'feather-alt'
  },
  {
    label: 'female',
    value: 'female'
  },
  {
    label: 'fighter-jet',
    value: 'fighter-jet'
  },
  {
    label: 'file',
    value: 'file'
  },
  {
    label: 'file-alt',
    value: 'file-alt'
  },
  {
    label: 'file-archive',
    value: 'file-archive'
  },
  {
    label: 'file-audio',
    value: 'file-audio'
  },
  {
    label: 'file-code',
    value: 'file-code'
  },
  {
    label: 'file-contract',
    value: 'file-contract'
  },
  {
    label: 'file-csv',
    value: 'file-csv'
  },
  {
    label: 'file-download',
    value: 'file-download'
  },
  {
    label: 'file-excel',
    value: 'file-excel'
  },
  {
    label: 'file-export',
    value: 'file-export'
  },
  {
    label: 'file-image',
    value: 'file-image'
  },
  {
    label: 'file-import',
    value: 'file-import'
  },
  {
    label: 'file-invoice',
    value: 'file-invoice'
  },
  {
    label: 'file-invoice-dollar',
    value: 'file-invoice-dollar'
  },
  {
    label: 'file-medical',
    value: 'file-medical'
  },
  {
    label: 'file-medical-alt',
    value: 'file-medical-alt'
  },
  {
    label: 'file-pdf',
    value: 'file-pdf'
  },
  {
    label: 'file-powerpoint',
    value: 'file-powerpoint'
  },
  {
    label: 'file-prescription',
    value: 'file-prescription'
  },
  {
    label: 'file-signature',
    value: 'file-signature'
  },
  {
    label: 'file-upload',
    value: 'file-upload'
  },
  {
    label: 'file-video',
    value: 'file-video'
  },
  {
    label: 'file-word',
    value: 'file-word'
  },
  {
    label: 'fill',
    value: 'fill'
  },
  {
    label: 'fill-drip',
    value: 'fill-drip'
  },
  {
    label: 'film',
    value: 'film'
  },
  {
    label: 'filter',
    value: 'filter'
  },
  {
    label: 'fingerprint',
    value: 'fingerprint'
  },
  {
    label: 'fire',
    value: 'fire'
  },
  {
    label: 'fire-alt',
    value: 'fire-alt'
  },
  {
    label: 'fire-extinguisher',
    value: 'fire-extinguisher'
  },
  {
    label: 'first-aid',
    value: 'first-aid'
  },
  {
    label: 'fish',
    value: 'fish'
  },
  {
    label: 'fist-raised',
    value: 'fist-raised'
  },
  {
    label: 'flag',
    value: 'flag'
  },
  {
    label: 'flag-checkered',
    value: 'flag-checkered'
  },
  {
    label: 'flag-usa',
    value: 'flag-usa'
  },
  {
    label: 'flask',
    value: 'flask'
  },
  {
    label: 'flushed',
    value: 'flushed'
  },
  {
    label: 'folder',
    value: 'folder'
  },
  {
    label: 'folder-minus',
    value: 'folder-minus'
  },
  {
    label: 'folder-open',
    value: 'folder-open'
  },
  {
    label: 'folder-plus',
    value: 'folder-plus'
  },
  {
    label: 'font',
    value: 'font'
  },
  {
    label: 'football-ball',
    value: 'football-ball'
  },
  {
    label: 'forward',
    value: 'forward'
  },
  {
    label: 'frog',
    value: 'frog'
  },
  {
    label: 'frown',
    value: 'frown'
  },
  {
    label: 'frown-open',
    value: 'frown-open'
  },
  {
    label: 'funnel-dollar',
    value: 'funnel-dollar'
  },
  {
    label: 'futbol',
    value: 'futbol'
  },
  {
    label: 'gamepad',
    value: 'gamepad'
  },
  {
    label: 'gas-pump',
    value: 'gas-pump'
  },
  {
    label: 'gavel',
    value: 'gavel'
  },
  {
    label: 'gem',
    value: 'gem'
  },
  {
    label: 'genderless',
    value: 'genderless'
  },
  {
    label: 'ghost',
    value: 'ghost'
  },
  {
    label: 'gift',
    value: 'gift'
  },
  {
    label: 'gifts',
    value: 'gifts'
  },
  {
    label: 'glass-cheers',
    value: 'glass-cheers'
  },
  {
    label: 'glass-martini',
    value: 'glass-martini'
  },
  {
    label: 'glass-martini-alt',
    value: 'glass-martini-alt'
  },
  {
    label: 'glass-whiskey',
    value: 'glass-whiskey'
  },
  {
    label: 'glasses',
    value: 'glasses'
  },
  {
    label: 'globe',
    value: 'globe'
  },
  {
    label: 'globe-africa',
    value: 'globe-africa'
  },
  {
    label: 'globe-americas',
    value: 'globe-americas'
  },
  {
    label: 'globe-asia',
    value: 'globe-asia'
  },
  {
    label: 'globe-europe',
    value: 'globe-europe'
  },
  {
    label: 'golf-ball',
    value: 'golf-ball'
  },
  {
    label: 'gopuram',
    value: 'gopuram'
  },
  {
    label: 'graduation-cap',
    value: 'graduation-cap'
  },
  {
    label: 'greater-than',
    value: 'greater-than'
  },
  {
    label: 'greater-than-equal',
    value: 'greater-than-equal'
  },
  {
    label: 'grimace',
    value: 'grimace'
  },
  {
    label: 'grin',
    value: 'grin'
  },
  {
    label: 'grin-alt',
    value: 'grin-alt'
  },
  {
    label: 'grin-beam',
    value: 'grin-beam'
  },
  {
    label: 'grin-beam-sweat',
    value: 'grin-beam-sweat'
  },
  {
    label: 'grin-hearts',
    value: 'grin-hearts'
  },
  {
    label: 'grin-squint',
    value: 'grin-squint'
  },
  {
    label: 'grin-squint-tears',
    value: 'grin-squint-tears'
  },
  {
    label: 'grin-stars',
    value: 'grin-stars'
  },
  {
    label: 'grin-tears',
    value: 'grin-tears'
  },
  {
    label: 'grin-tongue',
    value: 'grin-tongue'
  },
  {
    label: 'grin-tongue-squint',
    value: 'grin-tongue-squint'
  },
  {
    label: 'grin-tongue-wink',
    value: 'grin-tongue-wink'
  },
  {
    label: 'grin-wink',
    value: 'grin-wink'
  },
  {
    label: 'grip-horizontal',
    value: 'grip-horizontal'
  },
  {
    label: 'grip-lines',
    value: 'grip-lines'
  },
  {
    label: 'grip-lines-vertical',
    value: 'grip-lines-vertical'
  },
  {
    label: 'grip-vertical',
    value: 'grip-vertical'
  },
  {
    label: 'guitar',
    value: 'guitar'
  },
  {
    label: 'h-square',
    value: 'h-square'
  },
  {
    label: 'hamburger',
    value: 'hamburger'
  },
  {
    label: 'hammer',
    value: 'hammer'
  },
  {
    label: 'hamsa',
    value: 'hamsa'
  },
  {
    label: 'hand-holding',
    value: 'hand-holding'
  },
  {
    label: 'hand-holding-heart',
    value: 'hand-holding-heart'
  },
  {
    label: 'hand-holding-usd',
    value: 'hand-holding-usd'
  },
  {
    label: 'hand-lizard',
    value: 'hand-lizard'
  },
  {
    label: 'hand-middle-finger',
    value: 'hand-middle-finger'
  },
  {
    label: 'hand-paper',
    value: 'hand-paper'
  },
  {
    label: 'hand-peace',
    value: 'hand-peace'
  },
  {
    label: 'hand-point-down',
    value: 'hand-point-down'
  },
  {
    label: 'hand-point-left',
    value: 'hand-point-left'
  },
  {
    label: 'hand-point-right',
    value: 'hand-point-right'
  },
  {
    label: 'hand-point-up',
    value: 'hand-point-up'
  },
  {
    label: 'hand-pointer',
    value: 'hand-pointer'
  },
  {
    label: 'hand-rock',
    value: 'hand-rock'
  },
  {
    label: 'hand-scissors',
    value: 'hand-scissors'
  },
  {
    label: 'hand-spock',
    value: 'hand-spock'
  },
  {
    label: 'hands',
    value: 'hands'
  },
  {
    label: 'hands-helping',
    value: 'hands-helping'
  },
  {
    label: 'handshake',
    value: 'handshake'
  },
  {
    label: 'hanukiah',
    value: 'hanukiah'
  },
  {
    label: 'hard-hat',
    value: 'hard-hat'
  },
  {
    label: 'hashtag',
    value: 'hashtag'
  },
  {
    label: 'hat-cowboy',
    value: 'hat-cowboy'
  },
  {
    label: 'hat-cowboy-side',
    value: 'hat-cowboy-side'
  },
  {
    label: 'hat-wizard',
    value: 'hat-wizard'
  },
  {
    label: 'hdd',
    value: 'hdd'
  },
  {
    label: 'heading',
    value: 'heading'
  },
  {
    label: 'headphones',
    value: 'headphones'
  },
  {
    label: 'headphones-alt',
    value: 'headphones-alt'
  },
  {
    label: 'headset',
    value: 'headset'
  },
  {
    label: 'heart',
    value: 'heart'
  },
  {
    label: 'heart-broken',
    value: 'heart-broken'
  },
  {
    label: 'heartbeat',
    value: 'heartbeat'
  },
  {
    label: 'helicopter',
    value: 'helicopter'
  },
  {
    label: 'highlighter',
    value: 'highlighter'
  },
  {
    label: 'hiking',
    value: 'hiking'
  },
  {
    label: 'hippo',
    value: 'hippo'
  },
  {
    label: 'history',
    value: 'history'
  },
  {
    label: 'hockey-puck',
    value: 'hockey-puck'
  },
  {
    label: 'holly-berry',
    value: 'holly-berry'
  },
  {
    label: 'home',
    value: 'home'
  },
  {
    label: 'horse',
    value: 'horse'
  },
  {
    label: 'horse-head',
    value: 'horse-head'
  },
  {
    label: 'hospital',
    value: 'hospital'
  },
  {
    label: 'hospital-alt',
    value: 'hospital-alt'
  },
  {
    label: 'hospital-symbol',
    value: 'hospital-symbol'
  },
  {
    label: 'hot-tub',
    value: 'hot-tub'
  },
  {
    label: 'hotdog',
    value: 'hotdog'
  },
  {
    label: 'hotel',
    value: 'hotel'
  },
  {
    label: 'hourglass',
    value: 'hourglass'
  },
  {
    label: 'hourglass-end',
    value: 'hourglass-end'
  },
  {
    label: 'hourglass-half',
    value: 'hourglass-half'
  },
  {
    label: 'hourglass-start',
    value: 'hourglass-start'
  },
  {
    label: 'house-damage',
    value: 'house-damage'
  },
  {
    label: 'hryvnia',
    value: 'hryvnia'
  },
  {
    label: 'i-cursor',
    value: 'i-cursor'
  },
  {
    label: 'ice-cream',
    value: 'ice-cream'
  },
  {
    label: 'icicles',
    value: 'icicles'
  },
  {
    label: 'icons',
    value: 'icons'
  },
  {
    label: 'id-badge',
    value: 'id-badge'
  },
  {
    label: 'id-card',
    value: 'id-card'
  },
  {
    label: 'id-card-alt',
    value: 'id-card-alt'
  },
  {
    label: 'igloo',
    value: 'igloo'
  },
  {
    label: 'image',
    value: 'image'
  },
  {
    label: 'images',
    value: 'images'
  },
  {
    label: 'inbox',
    value: 'inbox'
  },
  {
    label: 'indent',
    value: 'indent'
  },
  {
    label: 'industry',
    value: 'industry'
  },
  {
    label: 'infinity',
    value: 'infinity'
  },
  {
    label: 'info',
    value: 'info'
  },
  {
    label: 'info-circle',
    value: 'info-circle'
  },
  {
    label: 'italic',
    value: 'italic'
  },
  {
    label: 'jedi',
    value: 'jedi'
  },
  {
    label: 'joint',
    value: 'joint'
  },
  {
    label: 'journal-whills',
    value: 'journal-whills'
  },
  {
    label: 'kaaba',
    value: 'kaaba'
  },
  {
    label: 'key',
    value: 'key'
  },
  {
    label: 'keyboard',
    value: 'keyboard'
  },
  {
    label: 'khanda',
    value: 'khanda'
  },
  {
    label: 'kiss',
    value: 'kiss'
  },
  {
    label: 'kiss-beam',
    value: 'kiss-beam'
  },
  {
    label: 'kiss-wink-heart',
    value: 'kiss-wink-heart'
  },
  {
    label: 'kiwi-bird',
    value: 'kiwi-bird'
  },
  {
    label: 'landmark',
    value: 'landmark'
  },
  {
    label: 'language',
    value: 'language'
  },
  {
    label: 'laptop',
    value: 'laptop'
  },
  {
    label: 'laptop-code',
    value: 'laptop-code'
  },
  {
    label: 'laptop-medical',
    value: 'laptop-medical'
  },
  {
    label: 'laugh',
    value: 'laugh'
  },
  {
    label: 'laugh-beam',
    value: 'laugh-beam'
  },
  {
    label: 'laugh-squint',
    value: 'laugh-squint'
  },
  {
    label: 'laugh-wink',
    value: 'laugh-wink'
  },
  {
    label: 'layer-group',
    value: 'layer-group'
  },
  {
    label: 'leaf',
    value: 'leaf'
  },
  {
    label: 'lemon',
    value: 'lemon'
  },
  {
    label: 'less-than',
    value: 'less-than'
  },
  {
    label: 'less-than-equal',
    value: 'less-than-equal'
  },
  {
    label: 'level-down-alt',
    value: 'level-down-alt'
  },
  {
    label: 'level-up-alt',
    value: 'level-up-alt'
  },
  {
    label: 'life-ring',
    value: 'life-ring'
  },
  {
    label: 'lightbulb',
    value: 'lightbulb'
  },
  {
    label: 'link',
    value: 'link'
  },
  {
    label: 'lira-sign',
    value: 'lira-sign'
  },
  {
    label: 'list',
    value: 'list'
  },
  {
    label: 'list-alt',
    value: 'list-alt'
  },
  {
    label: 'list-ol',
    value: 'list-ol'
  },
  {
    label: 'list-ul',
    value: 'list-ul'
  },
  {
    label: 'location-arrow',
    value: 'location-arrow'
  },
  {
    label: 'lock',
    value: 'lock'
  },
  {
    label: 'lock-open',
    value: 'lock-open'
  },
  {
    label: 'long-arrow-alt-down',
    value: 'long-arrow-alt-down'
  },
  {
    label: 'long-arrow-alt-left',
    value: 'long-arrow-alt-left'
  },
  {
    label: 'long-arrow-alt-right',
    value: 'long-arrow-alt-right'
  },
  {
    label: 'long-arrow-alt-up',
    value: 'long-arrow-alt-up'
  },
  {
    label: 'low-vision',
    value: 'low-vision'
  },
  {
    label: 'luggage-cart',
    value: 'luggage-cart'
  },
  {
    label: 'magic',
    value: 'magic'
  },
  {
    label: 'magnet',
    value: 'magnet'
  },
  {
    label: 'mail-bulk',
    value: 'mail-bulk'
  },
  {
    label: 'male',
    value: 'male'
  },
  {
    label: 'map',
    value: 'map'
  },
  {
    label: 'map-marked',
    value: 'map-marked'
  },
  {
    label: 'map-marked-alt',
    value: 'map-marked-alt'
  },
  {
    label: 'map-marker',
    value: 'map-marker'
  },
  {
    label: 'map-marker-alt',
    value: 'map-marker-alt'
  },
  {
    label: 'map-pin',
    value: 'map-pin'
  },
  {
    label: 'map-signs',
    value: 'map-signs'
  },
  {
    label: 'marker',
    value: 'marker'
  },
  {
    label: 'mars',
    value: 'mars'
  },
  {
    label: 'mars-double',
    value: 'mars-double'
  },
  {
    label: 'mars-stroke',
    value: 'mars-stroke'
  },
  {
    label: 'mars-stroke-h',
    value: 'mars-stroke-h'
  },
  {
    label: 'mars-stroke-v',
    value: 'mars-stroke-v'
  },
  {
    label: 'mask',
    value: 'mask'
  },
  {
    label: 'medal',
    value: 'medal'
  },
  {
    label: 'medkit',
    value: 'medkit'
  },
  {
    label: 'meh',
    value: 'meh'
  },
  {
    label: 'meh-blank',
    value: 'meh-blank'
  },
  {
    label: 'meh-rolling-eyes',
    value: 'meh-rolling-eyes'
  },
  {
    label: 'memory',
    value: 'memory'
  },
  {
    label: 'menorah',
    value: 'menorah'
  },
  {
    label: 'mercury',
    value: 'mercury'
  },
  {
    label: 'meteor',
    value: 'meteor'
  },
  {
    label: 'microchip',
    value: 'microchip'
  },
  {
    label: 'microphone',
    value: 'microphone'
  },
  {
    label: 'microphone-alt',
    value: 'microphone-alt'
  },
  {
    label: 'microphone-alt-slash',
    value: 'microphone-alt-slash'
  },
  {
    label: 'microphone-slash',
    value: 'microphone-slash'
  },
  {
    label: 'microscope',
    value: 'microscope'
  },
  {
    label: 'minus',
    value: 'minus'
  },
  {
    label: 'minus-circle',
    value: 'minus-circle'
  },
  {
    label: 'minus-square',
    value: 'minus-square'
  },
  {
    label: 'mitten',
    value: 'mitten'
  },
  {
    label: 'mobile',
    value: 'mobile'
  },
  {
    label: 'mobile-alt',
    value: 'mobile-alt'
  },
  {
    label: 'money-bill',
    value: 'money-bill'
  },
  {
    label: 'money-bill-alt',
    value: 'money-bill-alt'
  },
  {
    label: 'money-bill-wave',
    value: 'money-bill-wave'
  },
  {
    label: 'money-bill-wave-alt',
    value: 'money-bill-wave-alt'
  },
  {
    label: 'money-check',
    value: 'money-check'
  },
  {
    label: 'money-check-alt',
    value: 'money-check-alt'
  },
  {
    label: 'monument',
    value: 'monument'
  },
  {
    label: 'moon',
    value: 'moon'
  },
  {
    label: 'mortar-pestle',
    value: 'mortar-pestle'
  },
  {
    label: 'mosque',
    value: 'mosque'
  },
  {
    label: 'motorcycle',
    value: 'motorcycle'
  },
  {
    label: 'mountain',
    value: 'mountain'
  },
  {
    label: 'mouse',
    value: 'mouse'
  },
  {
    label: 'mouse-pointer',
    value: 'mouse-pointer'
  },
  {
    label: 'mug-hot',
    value: 'mug-hot'
  },
  {
    label: 'music',
    value: 'music'
  },
  {
    label: 'network-wired',
    value: 'network-wired'
  },
  {
    label: 'neuter',
    value: 'neuter'
  },
  {
    label: 'newspaper',
    value: 'newspaper'
  },
  {
    label: 'not-equal',
    value: 'not-equal'
  },
  {
    label: 'notes-medical',
    value: 'notes-medical'
  },
  {
    label: 'object-group',
    value: 'object-group'
  },
  {
    label: 'object-ungroup',
    value: 'object-ungroup'
  },
  {
    label: 'oil-can',
    value: 'oil-can'
  },
  {
    label: 'om',
    value: 'om'
  },
  {
    label: 'otter',
    value: 'otter'
  },
  {
    label: 'outdent',
    value: 'outdent'
  },
  {
    label: 'pager',
    value: 'pager'
  },
  {
    label: 'paint-brush',
    value: 'paint-brush'
  },
  {
    label: 'paint-roller',
    value: 'paint-roller'
  },
  {
    label: 'palette',
    value: 'palette'
  },
  {
    label: 'pallet',
    value: 'pallet'
  },
  {
    label: 'paper-plane',
    value: 'paper-plane'
  },
  {
    label: 'paperclip',
    value: 'paperclip'
  },
  {
    label: 'parachute-box',
    value: 'parachute-box'
  },
  {
    label: 'paragraph',
    value: 'paragraph'
  },
  {
    label: 'parking',
    value: 'parking'
  },
  {
    label: 'passport',
    value: 'passport'
  },
  {
    label: 'pastafarianism',
    value: 'pastafarianism'
  },
  {
    label: 'paste',
    value: 'paste'
  },
  {
    label: 'pause',
    value: 'pause'
  },
  {
    label: 'pause-circle',
    value: 'pause-circle'
  },
  {
    label: 'paw',
    value: 'paw'
  },
  {
    label: 'peace',
    value: 'peace'
  },
  {
    label: 'pen',
    value: 'pen'
  },
  {
    label: 'pen-alt',
    value: 'pen-alt'
  },
  {
    label: 'pen-fancy',
    value: 'pen-fancy'
  },
  {
    label: 'pen-nib',
    value: 'pen-nib'
  },
  {
    label: 'pen-square',
    value: 'pen-square'
  },
  {
    label: 'pencil-alt',
    value: 'pencil-alt'
  },
  {
    label: 'pencil-ruler',
    value: 'pencil-ruler'
  },
  {
    label: 'people-carry',
    value: 'people-carry'
  },
  {
    label: 'pepper-hot',
    value: 'pepper-hot'
  },
  {
    label: 'percent',
    value: 'percent'
  },
  {
    label: 'percentage',
    value: 'percentage'
  },
  {
    label: 'person-booth',
    value: 'person-booth'
  },
  {
    label: 'phone',
    value: 'phone'
  },
  {
    label: 'phone-alt',
    value: 'phone-alt'
  },
  {
    label: 'phone-slash',
    value: 'phone-slash'
  },
  {
    label: 'phone-square',
    value: 'phone-square'
  },
  {
    label: 'phone-square-alt',
    value: 'phone-square-alt'
  },
  {
    label: 'phone-volume',
    value: 'phone-volume'
  },
  {
    label: 'photo-video',
    value: 'photo-video'
  },
  {
    label: 'piggy-bank',
    value: 'piggy-bank'
  },
  {
    label: 'pills',
    value: 'pills'
  },
  {
    label: 'pizza-slice',
    value: 'pizza-slice'
  },
  {
    label: 'place-of-worship',
    value: 'place-of-worship'
  },
  {
    label: 'plane',
    value: 'plane'
  },
  {
    label: 'plane-arrival',
    value: 'plane-arrival'
  },
  {
    label: 'plane-departure',
    value: 'plane-departure'
  },
  {
    label: 'play',
    value: 'play'
  },
  {
    label: 'play-circle',
    value: 'play-circle'
  },
  {
    label: 'plug',
    value: 'plug'
  },
  {
    label: 'plus',
    value: 'plus'
  },
  {
    label: 'plus-circle',
    value: 'plus-circle'
  },
  {
    label: 'plus-square',
    value: 'plus-square'
  },
  {
    label: 'podcast',
    value: 'podcast'
  },
  {
    label: 'poll',
    value: 'poll'
  },
  {
    label: 'poll-h',
    value: 'poll-h'
  },
  {
    label: 'poo',
    value: 'poo'
  },
  {
    label: 'poo-storm',
    value: 'poo-storm'
  },
  {
    label: 'poop',
    value: 'poop'
  },
  {
    label: 'portrait',
    value: 'portrait'
  },
  {
    label: 'pound-sign',
    value: 'pound-sign'
  },
  {
    label: 'power-off',
    value: 'power-off'
  },
  {
    label: 'pray',
    value: 'pray'
  },
  {
    label: 'praying-hands',
    value: 'praying-hands'
  },
  {
    label: 'prescription',
    value: 'prescription'
  },
  {
    label: 'prescription-bottle',
    value: 'prescription-bottle'
  },
  {
    label: 'prescription-bottle-alt',
    value: 'prescription-bottle-alt'
  },
  {
    label: 'print',
    value: 'print'
  },
  {
    label: 'procedures',
    value: 'procedures'
  },
  {
    label: 'project-diagram',
    value: 'project-diagram'
  },
  {
    label: 'puzzle-piece',
    value: 'puzzle-piece'
  },
  {
    label: 'qrcode',
    value: 'qrcode'
  },
  {
    label: 'question',
    value: 'question'
  },
  {
    label: 'question-circle',
    value: 'question-circle'
  },
  {
    label: 'quidditch',
    value: 'quidditch'
  },
  {
    label: 'quote-left',
    value: 'quote-left'
  },
  {
    label: 'quote-right',
    value: 'quote-right'
  },
  {
    label: 'quran',
    value: 'quran'
  },
  {
    label: 'radiation',
    value: 'radiation'
  },
  {
    label: 'radiation-alt',
    value: 'radiation-alt'
  },
  {
    label: 'rainbow',
    value: 'rainbow'
  },
  {
    label: 'random',
    value: 'random'
  },
  {
    label: 'receipt',
    value: 'receipt'
  },
  {
    label: 'record-vinyl',
    value: 'record-vinyl'
  },
  {
    label: 'recycle',
    value: 'recycle'
  },
  {
    label: 'redo',
    value: 'redo'
  },
  {
    label: 'redo-alt',
    value: 'redo-alt'
  },
  {
    label: 'registered',
    value: 'registered'
  },
  {
    label: 'remove-format',
    value: 'remove-format'
  },
  {
    label: 'reply',
    value: 'reply'
  },
  {
    label: 'reply-all',
    value: 'reply-all'
  },
  {
    label: 'republican',
    value: 'republican'
  },
  {
    label: 'restroom',
    value: 'restroom'
  },
  {
    label: 'retweet',
    value: 'retweet'
  },
  {
    label: 'ribbon',
    value: 'ribbon'
  },
  {
    label: 'ring',
    value: 'ring'
  },
  {
    label: 'road',
    value: 'road'
  },
  {
    label: 'robot',
    value: 'robot'
  },
  {
    label: 'rocket',
    value: 'rocket'
  },
  {
    label: 'route',
    value: 'route'
  },
  {
    label: 'rss',
    value: 'rss'
  },
  {
    label: 'rss-square',
    value: 'rss-square'
  },
  {
    label: 'ruble-sign',
    value: 'ruble-sign'
  },
  {
    label: 'ruler',
    value: 'ruler'
  },
  {
    label: 'ruler-combined',
    value: 'ruler-combined'
  },
  {
    label: 'ruler-horizontal',
    value: 'ruler-horizontal'
  },
  {
    label: 'ruler-vertical',
    value: 'ruler-vertical'
  },
  {
    label: 'running',
    value: 'running'
  },
  {
    label: 'rupee-sign',
    value: 'rupee-sign'
  },
  {
    label: 'sad-cry',
    value: 'sad-cry'
  },
  {
    label: 'sad-tear',
    value: 'sad-tear'
  },
  {
    label: 'satellite',
    value: 'satellite'
  },
  {
    label: 'satellite-dish',
    value: 'satellite-dish'
  },
  {
    label: 'save',
    value: 'save'
  },
  {
    label: 'school',
    value: 'school'
  },
  {
    label: 'screwdriver',
    value: 'screwdriver'
  },
  {
    label: 'scroll',
    value: 'scroll'
  },
  {
    label: 'sd-card',
    value: 'sd-card'
  },
  {
    label: 'search',
    value: 'search'
  },
  {
    label: 'search-dollar',
    value: 'search-dollar'
  },
  {
    label: 'search-location',
    value: 'search-location'
  },
  {
    label: 'search-minus',
    value: 'search-minus'
  },
  {
    label: 'search-plus',
    value: 'search-plus'
  },
  {
    label: 'seedling',
    value: 'seedling'
  },
  {
    label: 'server',
    value: 'server'
  },
  {
    label: 'shapes',
    value: 'shapes'
  },
  {
    label: 'share',
    value: 'share'
  },
  {
    label: 'share-alt',
    value: 'share-alt'
  },
  {
    label: 'share-alt-square',
    value: 'share-alt-square'
  },
  {
    label: 'share-square',
    value: 'share-square'
  },
  {
    label: 'shekel-sign',
    value: 'shekel-sign'
  },
  {
    label: 'shield-alt',
    value: 'shield-alt'
  },
  {
    label: 'ship',
    value: 'ship'
  },
  {
    label: 'shipping-fast',
    value: 'shipping-fast'
  },
  {
    label: 'shoe-prints',
    value: 'shoe-prints'
  },
  {
    label: 'shopping-bag',
    value: 'shopping-bag'
  },
  {
    label: 'shopping-basket',
    value: 'shopping-basket'
  },
  {
    label: 'shopping-cart',
    value: 'shopping-cart'
  },
  {
    label: 'shower',
    value: 'shower'
  },
  {
    label: 'shuttle-van',
    value: 'shuttle-van'
  },
  {
    label: 'sign',
    value: 'sign'
  },
  {
    label: 'sign-in-alt',
    value: 'sign-in-alt'
  },
  {
    label: 'sign-language',
    value: 'sign-language'
  },
  {
    label: 'sign-out-alt',
    value: 'sign-out-alt'
  },
  {
    label: 'signal',
    value: 'signal'
  },
  {
    label: 'signature',
    value: 'signature'
  },
  {
    label: 'sim-card',
    value: 'sim-card'
  },
  {
    label: 'sitemap',
    value: 'sitemap'
  },
  {
    label: 'skating',
    value: 'skating'
  },
  {
    label: 'skiing',
    value: 'skiing'
  },
  {
    label: 'skiing-nordic',
    value: 'skiing-nordic'
  },
  {
    label: 'skull',
    value: 'skull'
  },
  {
    label: 'skull-crossbones',
    value: 'skull-crossbones'
  },
  {
    label: 'slash',
    value: 'slash'
  },
  {
    label: 'sleigh',
    value: 'sleigh'
  },
  {
    label: 'sliders-h',
    value: 'sliders-h'
  },
  {
    label: 'smile',
    value: 'smile'
  },
  {
    label: 'smile-beam',
    value: 'smile-beam'
  },
  {
    label: 'smile-wink',
    value: 'smile-wink'
  },
  {
    label: 'smog',
    value: 'smog'
  },
  {
    label: 'smoking',
    value: 'smoking'
  },
  {
    label: 'smoking-ban',
    value: 'smoking-ban'
  },
  {
    label: 'sms',
    value: 'sms'
  },
  {
    label: 'snowboarding',
    value: 'snowboarding'
  },
  {
    label: 'snowflake',
    value: 'snowflake'
  },
  {
    label: 'snowman',
    value: 'snowman'
  },
  {
    label: 'snowplow',
    value: 'snowplow'
  },
  {
    label: 'socks',
    value: 'socks'
  },
  {
    label: 'solar-panel',
    value: 'solar-panel'
  },
  {
    label: 'sort',
    value: 'sort'
  },
  {
    label: 'sort-alpha-down',
    value: 'sort-alpha-down'
  },
  {
    label: 'sort-alpha-down-alt',
    value: 'sort-alpha-down-alt'
  },
  {
    label: 'sort-alpha-up',
    value: 'sort-alpha-up'
  },
  {
    label: 'sort-alpha-up-alt',
    value: 'sort-alpha-up-alt'
  },
  {
    label: 'sort-amount-down',
    value: 'sort-amount-down'
  },
  {
    label: 'sort-amount-down-alt',
    value: 'sort-amount-down-alt'
  },
  {
    label: 'sort-amount-up',
    value: 'sort-amount-up'
  },
  {
    label: 'sort-amount-up-alt',
    value: 'sort-amount-up-alt'
  },
  {
    label: 'sort-down',
    value: 'sort-down'
  },
  {
    label: 'sort-numeric-down',
    value: 'sort-numeric-down'
  },
  {
    label: 'sort-numeric-down-alt',
    value: 'sort-numeric-down-alt'
  },
  {
    label: 'sort-numeric-up',
    value: 'sort-numeric-up'
  },
  {
    label: 'sort-numeric-up-alt',
    value: 'sort-numeric-up-alt'
  },
  {
    label: 'sort-up',
    value: 'sort-up'
  },
  {
    label: 'spa',
    value: 'spa'
  },
  {
    label: 'space-shuttle',
    value: 'space-shuttle'
  },
  {
    label: 'spell-check',
    value: 'spell-check'
  },
  {
    label: 'spider',
    value: 'spider'
  },
  {
    label: 'spinner',
    value: 'spinner'
  },
  {
    label: 'splotch',
    value: 'splotch'
  },
  {
    label: 'spray-can',
    value: 'spray-can'
  },
  {
    label: 'square',
    value: 'square'
  },
  {
    label: 'square-full',
    value: 'square-full'
  },
  {
    label: 'square-root-alt',
    value: 'square-root-alt'
  },
  {
    label: 'stamp',
    value: 'stamp'
  },
  {
    label: 'star',
    value: 'star'
  },
  {
    label: 'star-and-crescent',
    value: 'star-and-crescent'
  },
  {
    label: 'star-half',
    value: 'star-half'
  },
  {
    label: 'star-half-alt',
    value: 'star-half-alt'
  },
  {
    label: 'star-of-david',
    value: 'star-of-david'
  },
  {
    label: 'star-of-life',
    value: 'star-of-life'
  },
  {
    label: 'step-backward',
    value: 'step-backward'
  },
  {
    label: 'step-forward',
    value: 'step-forward'
  },
  {
    label: 'stethoscope',
    value: 'stethoscope'
  },
  {
    label: 'sticky-note',
    value: 'sticky-note'
  },
  {
    label: 'stop',
    value: 'stop'
  },
  {
    label: 'stop-circle',
    value: 'stop-circle'
  },
  {
    label: 'stopwatch',
    value: 'stopwatch'
  },
  {
    label: 'store',
    value: 'store'
  },
  {
    label: 'store-alt',
    value: 'store-alt'
  },
  {
    label: 'stream',
    value: 'stream'
  },
  {
    label: 'street-view',
    value: 'street-view'
  },
  {
    label: 'strikethrough',
    value: 'strikethrough'
  },
  {
    label: 'stroopwafel',
    value: 'stroopwafel'
  },
  {
    label: 'subscript',
    value: 'subscript'
  },
  {
    label: 'subway',
    value: 'subway'
  },
  {
    label: 'suitcase',
    value: 'suitcase'
  },
  {
    label: 'suitcase-rolling',
    value: 'suitcase-rolling'
  },
  {
    label: 'sun',
    value: 'sun'
  },
  {
    label: 'superscript',
    value: 'superscript'
  },
  {
    label: 'surprise',
    value: 'surprise'
  },
  {
    label: 'swatchbook',
    value: 'swatchbook'
  },
  {
    label: 'swimmer',
    value: 'swimmer'
  },
  {
    label: 'swimming-pool',
    value: 'swimming-pool'
  },
  {
    label: 'synagogue',
    value: 'synagogue'
  },
  {
    label: 'sync',
    value: 'sync'
  },
  {
    label: 'sync-alt',
    value: 'sync-alt'
  },
  {
    label: 'syringe',
    value: 'syringe'
  },
  {
    label: 'table',
    value: 'table'
  },
  {
    label: 'table-tennis',
    value: 'table-tennis'
  },
  {
    label: 'tablet',
    value: 'tablet'
  },
  {
    label: 'tablet-alt',
    value: 'tablet-alt'
  },
  {
    label: 'tablets',
    value: 'tablets'
  },
  {
    label: 'tachometer-alt',
    value: 'tachometer-alt'
  },
  {
    label: 'tag',
    value: 'tag'
  },
  {
    label: 'tags',
    value: 'tags'
  },
  {
    label: 'tape',
    value: 'tape'
  },
  {
    label: 'tasks',
    value: 'tasks'
  },
  {
    label: 'taxi',
    value: 'taxi'
  },
  {
    label: 'teeth',
    value: 'teeth'
  },
  {
    label: 'teeth-open',
    value: 'teeth-open'
  },
  {
    label: 'temperature-high',
    value: 'temperature-high'
  },
  {
    label: 'temperature-low',
    value: 'temperature-low'
  },
  {
    label: 'tenge',
    value: 'tenge'
  },
  {
    label: 'terminal',
    value: 'terminal'
  },
  {
    label: 'text-height',
    value: 'text-height'
  },
  {
    label: 'text-width',
    value: 'text-width'
  },
  {
    label: 'th',
    value: 'th'
  },
  {
    label: 'th-large',
    value: 'th-large'
  },
  {
    label: 'th-list',
    value: 'th-list'
  },
  {
    label: 'theater-masks',
    value: 'theater-masks'
  },
  {
    label: 'thermometer',
    value: 'thermometer'
  },
  {
    label: 'thermometer-empty',
    value: 'thermometer-empty'
  },
  {
    label: 'thermometer-full',
    value: 'thermometer-full'
  },
  {
    label: 'thermometer-half',
    value: 'thermometer-half'
  },
  {
    label: 'thermometer-quarter',
    value: 'thermometer-quarter'
  },
  {
    label: 'thermometer-three-quarters',
    value: 'thermometer-three-quarters'
  },
  {
    label: 'thumbs-down',
    value: 'thumbs-down'
  },
  {
    label: 'thumbs-up',
    value: 'thumbs-up'
  },
  {
    label: 'thumbtack',
    value: 'thumbtack'
  },
  {
    label: 'ticket-alt',
    value: 'ticket-alt'
  },
  {
    label: 'times',
    value: 'times'
  },
  {
    label: 'times-circle',
    value: 'times-circle'
  },
  {
    label: 'tint',
    value: 'tint'
  },
  {
    label: 'tint-slash',
    value: 'tint-slash'
  },
  {
    label: 'tired',
    value: 'tired'
  },
  {
    label: 'toggle-off',
    value: 'toggle-off'
  },
  {
    label: 'toggle-on',
    value: 'toggle-on'
  },
  {
    label: 'toilet',
    value: 'toilet'
  },
  {
    label: 'toilet-paper',
    value: 'toilet-paper'
  },
  {
    label: 'toolbox',
    value: 'toolbox'
  },
  {
    label: 'tools',
    value: 'tools'
  },
  {
    label: 'tooth',
    value: 'tooth'
  },
  {
    label: 'torah',
    value: 'torah'
  },
  {
    label: 'torii-gate',
    value: 'torii-gate'
  },
  {
    label: 'tractor',
    value: 'tractor'
  },
  {
    label: 'trademark',
    value: 'trademark'
  },
  {
    label: 'traffic-light',
    value: 'traffic-light'
  },
  {
    label: 'trailer',
    value: 'trailer'
  },
  {
    label: 'train',
    value: 'train'
  },
  {
    label: 'tram',
    value: 'tram'
  },
  {
    label: 'transgender',
    value: 'transgender'
  },
  {
    label: 'transgender-alt',
    value: 'transgender-alt'
  },
  {
    label: 'trash',
    value: 'trash'
  },
  {
    label: 'trash-alt',
    value: 'trash-alt'
  },
  {
    label: 'trash-restore',
    value: 'trash-restore'
  },
  {
    label: 'trash-restore-alt',
    value: 'trash-restore-alt'
  },
  {
    label: 'tree',
    value: 'tree'
  },
  {
    label: 'trophy',
    value: 'trophy'
  },
  {
    label: 'truck',
    value: 'truck'
  },
  {
    label: 'truck-loading',
    value: 'truck-loading'
  },
  {
    label: 'truck-monster',
    value: 'truck-monster'
  },
  {
    label: 'truck-moving',
    value: 'truck-moving'
  },
  {
    label: 'truck-pickup',
    value: 'truck-pickup'
  },
  {
    label: 'tshirt',
    value: 'tshirt'
  },
  {
    label: 'tty',
    value: 'tty'
  },
  {
    label: 'tv',
    value: 'tv'
  },
  {
    label: 'umbrella',
    value: 'umbrella'
  },
  {
    label: 'umbrella-beach',
    value: 'umbrella-beach'
  },
  {
    label: 'underline',
    value: 'underline'
  },
  {
    label: 'undo',
    value: 'undo'
  },
  {
    label: 'undo-alt',
    value: 'undo-alt'
  },
  {
    label: 'universal-access',
    value: 'universal-access'
  },
  {
    label: 'university',
    value: 'university'
  },
  {
    label: 'unlink',
    value: 'unlink'
  },
  {
    label: 'unlock',
    value: 'unlock'
  },
  {
    label: 'unlock-alt',
    value: 'unlock-alt'
  },
  {
    label: 'upload',
    value: 'upload'
  },
  {
    label: 'user',
    value: 'user'
  },
  {
    label: 'user-alt',
    value: 'user-alt'
  },
  {
    label: 'user-alt-slash',
    value: 'user-alt-slash'
  },
  {
    label: 'user-astronaut',
    value: 'user-astronaut'
  },
  {
    label: 'user-check',
    value: 'user-check'
  },
  {
    label: 'user-circle',
    value: 'user-circle'
  },
  {
    label: 'user-clock',
    value: 'user-clock'
  },
  {
    label: 'user-cog',
    value: 'user-cog'
  },
  {
    label: 'user-edit',
    value: 'user-edit'
  },
  {
    label: 'user-friends',
    value: 'user-friends'
  },
  {
    label: 'user-graduate',
    value: 'user-graduate'
  },
  {
    label: 'user-injured',
    value: 'user-injured'
  },
  {
    label: 'user-lock',
    value: 'user-lock'
  },
  {
    label: 'user-md',
    value: 'user-md'
  },
  {
    label: 'user-minus',
    value: 'user-minus'
  },
  {
    label: 'user-ninja',
    value: 'user-ninja'
  },
  {
    label: 'user-nurse',
    value: 'user-nurse'
  },
  {
    label: 'user-plus',
    value: 'user-plus'
  },
  {
    label: 'user-secret',
    value: 'user-secret'
  },
  {
    label: 'user-shield',
    value: 'user-shield'
  },
  {
    label: 'user-slash',
    value: 'user-slash'
  },
  {
    label: 'user-tag',
    value: 'user-tag'
  },
  {
    label: 'user-tie',
    value: 'user-tie'
  },
  {
    label: 'user-times',
    value: 'user-times'
  },
  {
    label: 'users',
    value: 'users'
  },
  {
    label: 'users-cog',
    value: 'users-cog'
  },
  {
    label: 'utensil-spoon',
    value: 'utensil-spoon'
  },
  {
    label: 'utensils',
    value: 'utensils'
  },
  {
    label: 'vector-square',
    value: 'vector-square'
  },
  {
    label: 'venus',
    value: 'venus'
  },
  {
    label: 'venus-double',
    value: 'venus-double'
  },
  {
    label: 'venus-mars',
    value: 'venus-mars'
  },
  {
    label: 'vial',
    value: 'vial'
  },
  {
    label: 'vials',
    value: 'vials'
  },
  {
    label: 'video',
    value: 'video'
  },
  {
    label: 'video-slash',
    value: 'video-slash'
  },
  {
    label: 'vihara',
    value: 'vihara'
  },
  {
    label: 'voicemail',
    value: 'voicemail'
  },
  {
    label: 'volleyball-ball',
    value: 'volleyball-ball'
  },
  {
    label: 'volume-down',
    value: 'volume-down'
  },
  {
    label: 'volume-mute',
    value: 'volume-mute'
  },
  {
    label: 'volume-off',
    value: 'volume-off'
  },
  {
    label: 'volume-up',
    value: 'volume-up'
  },
  {
    label: 'vote-yea',
    value: 'vote-yea'
  },
  {
    label: 'vr-cardboard',
    value: 'vr-cardboard'
  },
  {
    label: 'walking',
    value: 'walking'
  },
  {
    label: 'wallet',
    value: 'wallet'
  },
  {
    label: 'warehouse',
    value: 'warehouse'
  },
  {
    label: 'water',
    value: 'water'
  },
  {
    label: 'wave-square',
    value: 'wave-square'
  },
  {
    label: 'weight',
    value: 'weight'
  },
  {
    label: 'weight-hanging',
    value: 'weight-hanging'
  },
  {
    label: 'wheelchair',
    value: 'wheelchair'
  },
  {
    label: 'wifi',
    value: 'wifi'
  },
  {
    label: 'wind',
    value: 'wind'
  },
  {
    label: 'window-close',
    value: 'window-close'
  },
  {
    label: 'window-maximize',
    value: 'window-maximize'
  },
  {
    label: 'window-minimize',
    value: 'window-minimize'
  },
  {
    label: 'window-restore',
    value: 'window-restore'
  },
  {
    label: 'wine-bottle',
    value: 'wine-bottle'
  },
  {
    label: 'wine-glass',
    value: 'wine-glass'
  },
  {
    label: 'wine-glass-alt',
    value: 'wine-glass-alt'
  },
  {
    label: 'won-sign',
    value: 'won-sign'
  },
  {
    label: 'wrench',
    value: 'wrench'
  },
  {
    label: 'x-ray',
    value: 'x-ray'
  },
  {
    label: 'yen-sign',
    value: 'yen-sign'
  },
  {
    label: 'yin-yang',
    value: 'yin-yang'
  }
]

export default icons
